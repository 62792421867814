<!-- 展示页面 -->
<template>
  <div class="wrap" v-loading="loading">
    <!-- 公司信息 -->
    <div class="company_info_wrap">
      <!-- :style="companyData.company_logo?{background: 'url('+url+') no-repeat center center',backgroundSize:'cover'}:''" -->
      <div class="company_info">
        <div class="company_info_img">
          <!-- <img src="./../../assets/images/test/dddd.jpg" alt /> -->
          <img :src="companyData.company_logo" alt />
          <span></span>
        </div>
        <div class="company_info_show">
          <div>{{companyData.company_name}}</div>
          <p>
            <span>{{companyData.company_industry_name === ''?"":companyData.company_industry_name}}</span>
            <i>·</i>
            <span
              v-if="!(companyData.company_people_min === 0 && companyData.company_people_max === 0)"
            >
              <span>{{companyData.company_people_min}}</span>
              -
              <span>{{companyData.company_people_max}}</span>人
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- 热招职位 -->
    <div class="hot-box" v-if="companyData.jd_data &&companyData.jd_data.length>0">
      <div class="hot_job">
        <div class="show_title">
          热招职位
          <span class="look-hot" v-if="companyData.jd_data &&companyData.jd_data.length>3">
            <a @click="goHot">查看更多热招职位></a>
          </span>
        </div>
        <ul class="hot-card-wrap" v-if="companyData.jd_data &&companyData.jd_data.length>0">
          <li
            class="hot-card"
            @click="goDetails(item)"
            v-for="(item ,index) in companyData.jd_data.slice(0,3)"
            :key="index"
          >
            <p>
              <span class="jd_title">{{item.jd_title}}</span>
              <span>{{item.jd_salary_min}}{{-item.jd_salary_max}}元</span>
            </p>
            <p class="job-request">
              {{item.place_name}}&emsp;
              <span style="color:#c0c4cc">|</span>
              <!--  -->
              {{work_time[item.jd_exp_id].label}}&emsp;
              <span style="color:#c0c4cc">|</span>
              &emsp;
              <!--  -->
              {{educationBackground[item.jd_edu_id].label}}&emsp;
              <span
                class="job-time"
              >{{item.jd_update_time}}</span>
              <!-- <span
                style="color:#c0c4cc"
              >|</span>
              &emsp;{{item.jd_people_min}}人-->
              <!-- {{item.jd_people_max ===0? '人以上':'-'+item.jd_people_max+'人'}} -->
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="save-height">
      <div class="left">
        <div class="show_title">公司简介</div>
        <div style="position:relative">
          <div
            :class="{'company-intro':true,hidder:trigger}"
            ref="company_intro"
            v-html="companyData.company_introduce"
          ></div>
          <a v-if="triggerShow" class="trigger" href="javascript:;" @click="trigger=!trigger">
            <span v-if="trigger">
              关闭
              <i class="el-icon-arrow-up"></i>
            </span>
            <span v-else>
              展开
              <i class="el-icon-arrow-down"></i>
            </span>
          </a>
        </div>
        <!-- 公司环境 -->
        <div class="show_title">公司环境</div>
        <div class="company-environment">
          <el-carousel indicator-position="outside" height="300px" v-if="image_company.length>0">
            <el-carousel-item v-for="(item,index) in image_company" :key="index">
              <img :src="item" alt />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="show_title">公司位置</div>
        <div class="map-wrap">
          <!-- 腾讯地图 -->
          <div ref="map" class="map"></div>
          <div class="map-location">
            <i class="el-icon-location-outline"></i>
            <span style="font-size:14px">{{companyData.company_map_detail}}</span>
          </div>
        </div>
        <div v-if="companyData.jd_data &&companyData.jd_data.length>0">
          <div class="show_title">
            <a ref="hotPosition">所有职位</a>
          </div>
          <div class="more-hot">
            <MoreHotComponent :jobHotList="companyData.jd_data"></MoreHotComponent>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="company_environment">
          <div class="show_title">团队风采</div>
          <div class="company_width">
            <!-- <img src="./../../assets/images/show/tow.png" alt /> -->
            <el-carousel
              :interval="4000"
              height="200px"
              autoplay
              direction="vertical"
              v-if="image_team.length>0"
            >
              <el-carousel-item v-for="(item, index) in image_team" :key="index">
                <img :src="item" alt @click="imgBig(item)" />
                <span></span>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="show_title">风云人物</div>
          <div v-for="(item,index) in companyData.sign_data" :key="index">
            <!-- <el-carousel indicator-position="outside" height="300px" class="no-arrows">
              <el-carousel-item v-for="(item,index) in companyData.sign_data" :key="index">
                <div class="img-wrap">
                  <img :src="item.sign_img" alt title="风云人物" />
                  <div>
                    <p>{{item.sign_name}}</p>
                  </div>
                </div>
                <p class="post-info">{{item.sign_position}}</p>
                <div class="post-info" v-html="item.sign_introduce"></div>
              </el-carousel-item>
            </el-carousel>-->
            <div class="img-wrap">
              <img :src="item.sign_img" alt title="风云人物" />
              <div>
                <p>{{item.sign_name}}</p>
              </div>
            </div>
            <p class="post-info">
              <span>职位：</span>
              {{item.sign_position}}
            </p>
            <div class="post-info" v-html="item.sign_introduce"></div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="团队风采" :visible.sync="centerDialogVisible" width="40%" center>
      <img :src="imgBigUrl" alt class="img-big" />
    </el-dialog>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import MoreHotComponent from "./../../components/moreHot";
import { getCompanyShow } from "./../../api/company/company";
import { mapState, mapActions } from "vuex";
import {
  company_scale,
  work_time,
  educationBackground,
} from "./../../utils/searchtype";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { MoreHotComponent },
  data() {
    //这里存放数据
    return {
      url:
        "https://img.bosszhipin.com/beijin/mcs/banner/20200411/dd20980d3f6ca549a6d02d47fde90ade.jpg?x-oss-process=image/format,jpg",
      loading: true,
      // 公司简介收放开关
      triggerShow: false,
      trigger: false,
      //更多热招职位
      jobHotList: [{}],
      //api公司展示
      // companyData: {},
      image_company: [],
      image_team: [],
      //团队风采图片变大
      centerDialogVisible: false,
      imgBigUrl: "",
      educationBackground: [],
      company_scale: [],
      work_time: [],
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      companyData: (state) => state.company.company_data,
    }),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    ...mapActions({
      getCompanyShowData: "company/getCompanyShowData",
    }),
    //查看更多热招职位
    goHot() {
      console.log(this.$refs.hotPosition.offsetTop);
      let scrolltop = 0;
      let isOK = false;
      let time = setInterval(() => {
        console.log("1");
        if (scrolltop <= this.$refs.hotPosition.offsetTop) {
          scrolltop += 80;
          document.documentElement.scrollTop = scrolltop;
          if (scrolltop >= this.$refs.hotPosition.offsetTop) {
            clearInterval(time);
            time = null;
          }
        }
      }, 16);
    },
    //腾讯地图
    mapInit() {
      // console.log(this.companyData)
      let lat = this.companyData.company_place_latitude || 39.749146;
      let lng = this.companyData.company_place_longitude || 116.143265;
      console.log(lat, lng);
      var center = new qq.maps.LatLng(lat, lng);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      var map = new qq.maps.Map(this.$refs.map, {
        center: center, //设置地图中心点坐标
        zoom: 16, //设置地图缩放级别
      });
      var marker = new qq.maps.Marker({
        position: center,
        map: map,
        // flat: true,
        // animation: qq.maps.MarkerAnimation.BOUNCE
      });
      var size = new qq.maps.Size(25, 25),
        origin = new qq.maps.Point(0, 0),
        // url = require('./../../assets/images/map/mapMark.png')
        markerIcon = new qq.maps.MarkerImage(
          // '/static/map/mapMark.png',
          require("./../../assets/images/map/mapMark.png"),
          size,
          origin
        );
      marker.setIcon(markerIcon);
    },
    //上方进入热招详情
    goDetails(item) {
      // console.log(item)
      this.$router.push({ path: "/jobdetails", query: { id: item.id } });
    },
    //图片放大
    imgBig(url) {
      this.centerDialogVisible = true;
      this.imgBigUrl = url;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.educationBackground = educationBackground;
    this.company_scale = company_scale;
    this.work_time = work_time;
    //api数据
    // this.getCompanyShowData({
    //   type: 'all',
    //   succeed: res => {
    //     this.loading = false
    //     this.companyData.company_industry_name = res.data.company_industry_name.join(
    //       '/'
    //     )
    //     res.data.img_data.filter(item => {
    //       if (item.type === 'index') {
    //         return this.image_company.push(item.img_data)
    //       } else if (item.type === 'team') {
    //         console.log(item)
    //         return this.image_team.push(item.img_data)
    //       }
    //     })
    // this.$nextTick(() => {
    //   this.mapInit()
    //   //公司介绍高度超过200 显示展开
    //   // console.log(this.$refs.company_intro.scrollHeight)
    //   if (this.$refs.company_intro.scrollHeight > 200) {
    //     this.triggerShow = true
    //     console.log(this.triggerShow)
    //   } else {
    //     this.triggerShow = false
    //   }
    // })
    // }
    // })
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {
    console.log("278");
    this.getCompanyShowData({
      type: "all",
      succeed: (res) => {
        this.loading = false;
        //存入vuex
        this.$store.commit("changeLogo", {
          logo: res.data.company_logo,
          name: res.data.company_name,
        });
        let company_cn_name = null;
        if (res.data.company_place_data.province_name === undefined) {
          company_cn_name =
            res.data.company_place_data.city_name +
            res.data.company_place_data.district_name;
        } else {
          company_cn_name =
            res.data.company_place_data.province_name +
            res.data.company_place_data.city_name +
            res.data.company_place_data.district_name;
        }

        sessionStorage.setItem("logo", res.data.company_logo);
        sessionStorage.setItem("name", res.data.company_name);
        sessionStorage.setItem("place", company_cn_name);

        console.log(res);
        if (res.data.company_industry_data.second_industry_name != undefined) {
          this.companyData.company_industry_name =
            res.data.company_industry_data.first_industry_name +
            res.data.company_industry_data.second_industry_name;
        } else {
          this.companyData.company_industry_name = "";
        }

        let teamArr = [],
          indexArr = [];
        res.data.img_data.filter((item) => {
          if (item.type === "index") {
            return teamArr.push(item.img_data);
          } else if (item.type === "team") {
            console.log(item);
            return indexArr.push(item.img_data);
          }
        });
        this.image_company = teamArr;
        this.image_team = indexArr;
        this.$nextTick(() => {
          this.mapInit();
          //公司介绍高度超过200 显示展开
          // console.log(this.$refs.company_intro.scrollHeight)
          if (this.$refs.company_intro.scrollHeight > 200) {
            this.triggerShow = true;
            console.log(this.triggerShow);
          } else {
            this.triggerShow = false;
          }
        });
      },
    });
  }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style  scoped lang="scss">
/* //@import url(); 引入公共css类 */

.wrap {
  @include padding;
  margin-top: 80px;
  // background-color: red;
  min-width: 1100px;
}
// 热招职位
// .hot-box {
//   width: 100% !important;
//   background: red;
// }
.jd_title {
  display: inline-block;
  width: 120px;
  // background-color: red;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.hot_job {
  // background-color: #f2f2f5;
  overflow: hidden;
  padding-bottom: 10px;
  margin: 0 auto;
}
.hot-card-wrap {
  white-space: nowrap;
}
.look-hot {
  float: right;
  font-size: 14px;
  cursor: pointer;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}
.look-hot a {
  color: $selfColor;
}
// 热招职位小卡片
.hot-card {
  width: 32%;
  height: 100px;
  box-sizing: border-box;
  display: inline-block;
  background-color: #fff;
  margin-right: 15px;
  flex: none;
  padding: 19px 20px 17px;
  box-shadow: $box-shadow;
  cursor: pointer;
}
.hot-card p {
  margin-top: 0;
}
.hot-card .jd_title + span {
  float: right;
  color: $main_color;
}
.job-request {
  color: #9fa3af;
  font-size: 12px;
}
.job-time {
  float: right;
}
.company_info_wrap {
  width: 100%;
  background-color: $main2_color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
    border-radius:5px
}
/* 图片居中 */
.company_info {
  width: 80%;
  min-width: 1200px;
  height: 150px;
  margin: 0 auto;
}
.company_info_img {
  vertical-align: middle;
  height: 100%;
  width: 150px;
  float: left;
  margin-left: 19px;
}
.company_info_img img {
  width: 110px;
  height: 110px;
  border-radius: 10px;
  vertical-align: middle;
}
.company_info_img:nth-child(1) span {
  width: 0;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.company_info_show div {
  font-size: 30px;
  color: #fff;
  padding-top: 30px;
}
.company_info_show p {
  padding: 15px 0 0 30px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}
/* 空格不好用 以此解决 */
.company_info_show p i {
  padding: 0 15px;
}
// 父元素高度
.save-height::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}
/* 左面 */
.left {
  margin-top: 20px;
  float: left;
  width: 70%;
  min-width: 850px;
  padding-right: 20px;
  border-right: 1px solid #f1f1f1;
}
// 公司简介
.company-intro {
  position: relative;
  max-height: 200px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: $selfColor;
  font-size: $jd_fontSize;
  overflow: hidden;
  line-height: 28px;
}
// 收放开关
.trigger {
  color: $selfColor;
  display: inline-block;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  width: 70px;
  height: 20px;
  line-height: 20px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 1%,
    rgba(249, 249, 249, 1) 35%,
    rgb(249, 249, 249) 100%
  );
  text-align: right;
}
.hidder {
  overflow: visible;
  max-height: none;
}
.map-wrap {
  box-shadow: $box-shadow;
}
// 公司环境
.company-environment {
  min-height: 300px;
}
.company-environment img {
  // width: 100%;
  height: 300px;
}
// 地图
.map {
  width: 100%;
  height: 200px;
}
.map-location {
  padding-left: 10px;
  width: 100%;
  height: 50px;
  background-color: #fff;
  line-height: 50px;
  font-size: 16px;
  color: #62687a;
}
.map-location i {
  margin-right: 10px;
}
// 更多热门招聘
/* 右面 */
.right {
  float: right;
  /* width: 29%; */
  max-width: 200px;
}
/* 公司环境 */
.company_environment {
  position: relative;
}
// 风云人物
.img-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.img-wrap img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.img-big {
  width: 100%;
}
// 职位与介绍
.post-info {
  color: #414a60;
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  word-wrap: break-word;
}
/* 内容区域宽度 */
.company_environment .company_width {
  width: 200px;
  /* min-width: 1200px; */
  margin: 0 auto;
}
.company_width img {
  width: 100%;
  height: 100%;
}
/* 轮播图 图片居中 */
.el-carousel__item {
  vertical-align: middle;
  // background-color: #444c5f;
  text-align: center;
}
.el-carousel__item img {
  vertical-align: middle;
}
.el-carousel__item span {
  width: 0;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.show_title {
  // padding-left: 50px;
  margin-top: 30px;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  &::after {
    content: "";
    width: 20px;
    display: table;
    border: 1.5px solid $main_color;
    margin-top: 7px;
    margin-bottom: 20px;
  }
}
// 团队风采不要箭头
.no-arrows /deep/.el-carousel__arrow {
  display: none;
}
</style>